<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AppStorage } from 'cuf-expo-bridge'

export default {
  name: 'verifyResetToken',
  computed: {
    ...mapGetters({ currentClient: 'currentClient' })
  },
  data () {
    return {
      login: null
    }
  },
  created () {
    console.log(this.$route.params.token)
    this.checkCurrentLogin()
  },
  methods: {
    checkCurrentLogin () {
      if (this.currentClient || !this.$route.params.token) {
        this.$router.replace(this.$route.query.redirect || '/documents')
      } else {
        this.$http.get('/auth/user_login?token=' + this.$route.params.token)
          .then(response => {
            if (response.data.login) {
              this.login = response.data.login
              this.submitLogin()
            } else {
              this.$router.replace(this.$route.query.redirect || '/documents')
            }
          })
      }
    },
    submitLogin () {
      this.$http.post('/auth/', { login: this.login, password: this.$route.params.token })
        .then(response => this.loginSuccessful(response))
    },
    loginSuccessful (req) {
      if (!req.data.token) {
        this.loginFailed()
        return
      }
      this.error = false
      // const obj = JwtDecode(req.data.token)

      AppStorage.set('token', req.data.token)
        .then(() => this.$store.dispatch('login', req.data.token))
        .then(() => {
        // this.$store.dispatch('changeLocale', obj.locale)
        // this.$i18n.locale = obj.locale
          this.$router.replace(this.$route.query.redirect || '/documents')
        })
    },
    loginFailed () {
      this.$store.dispatch('logout')
      AppStorage.remove('token')
      this.$swal({
        toast: false,
        position: 'center',
        showConfirmButton: false,
        timer: 1000,

        type: 'error',
        text: 'Login failed!'
      })
    }
  }
}
</script>
